import { DateString, Hours } from "@pentacode/openapi";
import {
    Company,
    Department,
    Employee,
    Position,
    TimeEntry,
    TimeEntryPublishedInfo,
    TimeEntryType,
    TimeLogEvent,
    Venue,
} from "../model";
import { PentacodeAPIModels } from "./api";
import { getHexColor } from "../colors";

// turns null values to undefined to represent an empty value in typeorm and the database
export function nullToUndefined<T extends { [key: string]: unknown }>(obj: T): { [K in keyof T]: Exclude<T[K], null> } {
    return Object.keys(obj).reduce((acc, key) => {
        acc[key] = obj[key] === null ? undefined : obj[key];
        return acc;
    }, {} as any); // eslint-disable-line @typescript-eslint/no-explicit-any
}

// turns empty values ['undefined' and empty string ''] to null to be returned as JSON in API
export function emptyToNull<T extends { [key: string]: unknown }>(
    obj: T
): { [K in keyof T]: Exclude<T[K], undefined> } {
    return Object.keys(obj).reduce((acc, key) => {
        acc[key] = obj[key] === undefined || obj[key] === "" ? null : obj[key];
        return acc;
    }, {} as any); // eslint-disable-line @typescript-eslint/no-explicit-any
}

export function employeeModelToAPISchema(
    employee: Pick<Employee, "id" | "firstName" | "lastName"> & Partial<Employee>,
    includeRestricted: boolean
): PentacodeAPIModels["Employee"] {
    return emptyToNull(
        includeRestricted
            ? {
                  id: employee.id,
                  firstName: employee.firstName,
                  lastName: employee.lastName,
                  callName: employee.callName,
                  address: employee.address,
                  city: employee.city,
                  postalCode: employee.postalCode,
                  email: employee.email,
                  phone: employee.phone,
                  phone2: employee.phone2,
                  birthday: employee.birthday as DateString,
                  birthName: employee.birthName,
                  birthCity: employee.birthCity,
                  birthCountry: employee.birthCountry,
                  nationality: employee.nationality,
                  gender: employee.gender,
                  taxId: employee.taxId,
                  socialSecurityNumber: employee.socialSecurityNumber,
                  notes: employee.notes,
                  staffNumber: employee.staffNumber?.toString(),
                  avatar: employee.avatar,
                  positions:
                      employee.positions?.map(({ id, name, color }) => ({
                          id,
                          name,
                          color: getHexColor(color),
                      })) || [],
                  timeLogPin: employee.timePin,
                  status: employee.status,
              }
            : {
                  id: employee.id,
                  firstName: employee.firstName,
                  lastName: employee.lastName,
                  callName: employee.callName,
                  staffNumber: employee.staffNumber?.toString(),
                  avatar: employee.avatar,
                  positions:
                      employee.positions?.map(({ id, name, color }) => ({
                          id,
                          name,
                          color: getHexColor(color),
                      })) || [],
                  timeLogPin: employee.timePin,
                  status: employee.status,
              }
    );
}

export function companyModelToAPISchema(company: Company): PentacodeAPIModels["Company"] {
    return emptyToNull({
        id: company.id,
        name: company.name,
        venues: company.venues.map(venueModelToAPISchema),
    });
}

export function venueModelToAPISchema(venue: Pick<Venue, "id" | "name"> & Partial<Venue>): PentacodeAPIModels["Venue"] {
    const { id, name, venueNumber, holidays, enabledHolidays, departments } = venue;
    return emptyToNull({
        id,
        name,
        venueNumber,
        holidays: holidays || enabledHolidays,
        departments: departments?.map(departmentModelToAPISchema) || [],
    });
}

export function departmentModelToAPISchema(
    department: Pick<Department, "id" | "name" | "rosterOrder"> & Partial<Department>
): PentacodeAPIModels["Department"] {
    return emptyToNull({
        id: department.id,
        name: department.name,
        color: getHexColor(department.color),
        positions: department.positions?.map(positionModelToAPISchema),
        timeScheduleEmployeeOrder: department.rosterOrder?.map((id) => Number(id)),
    });
}

export function positionModelToAPISchema(
    position: Pick<Position, "id" | "name"> & Partial<Position>
): PentacodeAPIModels["Position"] {
    return emptyToNull({
        id: position.id,
        name: position.name,
        color: getHexColor(position.color),
    });
}

export function positionAPISchemaToModel(position: PentacodeAPIModels["Position"]): Position {
    return new Position(
        nullToUndefined({
            id: position.id,
            name: position.name,
            color: position.color,
        })
    );
}

export function timeEntryModelToAPISchema(company: Company, entry: TimeEntry): PentacodeAPIModels["TimeEntry"] {
    const position = company.getPosition(entry.positionId!)?.position;
    const type = entry.type === TimeEntryType.Work ? "work" : (entry.type as PentacodeAPIModels["TimeEntry"]["type"]);

    return {
        id: entry.id,
        date: entry.date,
        status: getStatus(entry),
        startPlanned: entry.startPlanned?.toISOString() || null,
        endPlanned: entry.endPlanned?.toISOString() || null,
        startLogged: entry.startLogged?.toISOString() || null,
        endLogged: entry.endLogged?.toISOString() || null,
        startFinal: entry.startFinal?.toISOString() || null,
        endFinal: entry.endFinal?.toISOString() || null,
        currentBreakStart: entry.startBreak?.toISOString() || null,
        employeeId: entry.employeeId || null,
        type,
        breakPlanned: typeof entry.breakPlanned === "number" ? entry.breakPlanned : (0 as Hours),
        breakLogged: typeof entry.breakLogged === "number" ? entry.breakLogged : (0 as Hours),
        breakFinal: typeof entry.break === "number" ? entry.break : (0 as Hours),
        result: entry.result || undefined,
        position: position ? positionModelToAPISchema(position) : null,
        mealsBreakfast: entry.mealsBreakfast,
        published: timeEntryPublishedInfoModelToAPISchema(company, entry.published),
        seen: timeEntryPublishedInfoModelToAPISchema(company, entry.seen),
        mealsLunch: entry.mealsLunch,
        mealsDinner: entry.mealsDinner,
        comment: entry.comment,
    };
}

export function timeEntryAPISchemaToModel(entry: PentacodeAPIModels["TimeEntry"]): TimeEntry {
    const timeEntryBase = nullToUndefined({
        id: entry.id,
        date: entry.date,
        startPlanned: entry.startPlanned ? new Date(entry.startPlanned) : null,
        endPlanned: entry.endPlanned ? new Date(entry.endPlanned) : null,
        startLogged: entry.startLogged ? new Date(entry.startLogged) : null,
        endLogged: entry.endLogged ? new Date(entry.endLogged) : null,
        startFinal: entry.startFinal ? new Date(entry.startFinal) : null,
        endFinal: entry.endFinal ? new Date(entry.endFinal) : null,
        startBreak: entry.currentBreakStart ? new Date(entry.currentBreakStart) : null,
        employeeId: entry.employeeId || null,
        type: entry.type as TimeEntryType,
        breakPlanned: entry.breakPlanned,
        breakLogged: entry.breakLogged,
        break: entry.breakFinal,
        result: entry.result,
        positionId: entry.position?.id || null,
        mealsBreakfast: entry.mealsBreakfast,
        published: entry.published ? timeEntryPublishedInfoAPISchemaToModel(entry.published) : null,
        seen: entry.seen ? timeEntryPublishedInfoAPISchemaToModel(entry.seen) : null,
        mealsLunch: entry.mealsLunch,
        mealsDinner: entry.mealsDinner,
        comment: entry.comment,
        position: entry.position ? positionAPISchemaToModel(entry.position) : null,
    });

    return new TimeEntry(timeEntryBase);
}

export function timeEntryPublishedInfoModelToAPISchema(
    company: Company,
    info: TimeEntryPublishedInfo | null
): PentacodeAPIModels["TimeEntryPublishedInfo"] | null | undefined {
    if (!info) {
        return info;
    }

    const position = info.positionId && company.getPosition(info.positionId)?.position;

    return {
        ...info,
        position: position ? positionModelToAPISchema(position) : null,
        time: info.time.toISOString(),
        deleted: info.deleted?.toISOString() || null,
        startPlanned: info.startPlanned?.toISOString() || null,
        endPlanned: info.endPlanned?.toISOString() || null,
    };
}

export function timeEntryPublishedInfoAPISchemaToModel(
    info: PentacodeAPIModels["TimeEntryPublishedInfo"]
): TimeEntryPublishedInfo {
    return {
        time: new Date(info.time),
        deleted: info.deleted ? new Date(info.deleted) : null,
        employeeId: info.employeeId,
        date: info.date,
        positionId: info.position?.id || null,
        startPlanned: info.startPlanned ? new Date(info.startPlanned) : null,
        endPlanned: info.endPlanned ? new Date(info.endPlanned) : null,
        comment: info.comment,
    };
}

export function timeLogEventModelToAPISchema({
    id,
    action,
    deviceId,
    employeeId,
    positionId,
    timeEntryId,
    time,
    status,
    rejectedReason,
    location,
    image,
}: TimeLogEvent): PentacodeAPIModels["TimeLogEvent"] {
    return {
        id,
        action,
        deviceId,
        employeeId,
        positionId,
        timeEntryId,
        time: time.toISOString(),
        status,
        rejectedReason,
        location,
        image,
    };
}

function getStatus(entry: TimeEntry): PentacodeAPIModels["TimeEntry"]["status"] {
    if (entry.type !== TimeEntryType.Work) {
        return "completed";
    }

    if (entry.endFinal) {
        return "completed";
    }

    if (entry.startBreak) {
        return "paused";
    }

    if (entry.startFinal) {
        return "ongoing";
    }

    return "scheduled";
}
