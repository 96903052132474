import { Dialog } from "@pentacode/app/src/elements/dialog";
import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { fullbleed } from "@pentacode/app/src/styles/mixins";
import { PentacodeAPIModels } from "@pentacode/core/src/rest/api";
import "@pentacode/app/src/elements/avatar";
import { getVacationEntitlement, hour, overlap } from "@pentacode/core/src/time";
import {
    dateAdd,
    formatDateShort,
    formatNumber,
    formatTimeDistance,
    monthNames,
    parseDateString,
    parseTimes,
    toDateString,
    toDurationString,
    toTimeString,
    getRange,
} from "@pentacode/core/src/util";
import { animations } from "@pentacode/app/src/styles";
import { Textarea } from "@pentacode/app/src/elements/textarea";
import { Drawer } from "@pentacode/app/src/elements/drawer";
import { singleton } from "@pentacode/app/src/lib/singleton";
import { CaptureImage } from "@pentacode/app/src/elements/capture-image";
import { PerformActionParams, getCurrentShiftsByStatus } from "../lib/time_logger";
import { Location, TimeLogAction } from "@pentacode/core/src/model";
import { TimeLogController } from "../controllers/time-log";
import { until } from "lit/directives/until.js";
import { Balance } from "@pentacode/app/src/elements/balance";
import "./note";
import { Checkbox } from "@pentacode/app/src/elements/checkbox";
import { Hours, add } from "@pentacode/openapi";

type ScheduledOption = PentacodeAPIModels["TimeLogOption"]["scheduled"][number];
type UnscheduledOption = PentacodeAPIModels["TimeLogOption"]["unscheduled"][number];

type SelectedAction = {
    action: PentacodeAPIModels["TimeLogAction"];
    option: ScheduledOption | UnscheduledOption;
} | null;

@customElement("ptc-time-log-options-dialog")
export class OptionsDialog extends Dialog<PentacodeAPIModels["TimeLogOption"], PerformActionParams> {
    @state()
    private _option: PentacodeAPIModels["TimeLogOption"];

    @state()
    private _selectedAction: SelectedAction = null;

    @state()
    private _image?: string | null = null;

    @state()
    private _vacationBalances?: Promise<PentacodeAPIModels["VacationBalance"][]>;

    @state()
    private _monthTimeBalances?: Promise<PentacodeAPIModels["TimeBalance"][]>;

    @state()
    private _dayTimeBalances?: Promise<PentacodeAPIModels["TimeBalance"][]>;

    @state()
    private _notes?: Promise<PentacodeAPIModels["Note"][]>;

    private _locationPromise?: Promise<Location> | null = null;

    @query("#detailsDrawer")
    private _detailsDrawer: Drawer;

    @query("#formDrawer")
    private _formDrawer: Drawer;

    @singleton("ptc-capture-image")
    private _captureImageDialog: CaptureImage;

    private _controller = new TimeLogController(this);

    preventDismiss = true;

    show(option: PentacodeAPIModels["TimeLogOption"]) {
        this._option = option;
        this._selectedAction = null;
        this._image = null;
        this._locationPromise = null;
        const now = new Date();
        const yearRange = getRange(now, "year");
        const monthRange = getRange(now, "month");
        const dayRange = getRange(monthRange.from, "day");

        if (this._option.displayStatistics) {
            this._vacationBalances = this._controller.client.api
                .getVacationBalances({
                    filters: [{ type: "employeeId", value: this._option.employee.id }],
                    resolution: "year",
                    ...yearRange,
                })
                .then((res) => res.data);
            this._monthTimeBalances = this._controller.client.api
                .getTimeBalances({
                    filters: [{ type: "employeeId", value: this._option.employee.id }],
                    resolution: "month",
                    ...monthRange,
                })
                .then((res) => res.data);
            this._dayTimeBalances = this._controller.client.api
                .getTimeBalances({
                    filters: [{ type: "employeeId", value: this._option.employee.id }],
                    resolution: "day",
                    ...dayRange,
                })
                .then((res) => res.data);
            this._notes = this._controller.client.api
                .getNotes({
                    from: toDateString(now),
                    to: dateAdd(toDateString(now), { days: 1 }),
                    filters: [{ type: "employeeId", value: this._option.employee.id }],
                })
                .then((res) => res.data);
        }
        return super.show();
    }

    cancel() {
        this._selectedAction = null;
        this._image = null;
        this.done();
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("_selectedAction")) {
            this._detailsDrawer.collapsed = !!this._selectedAction;
            this._formDrawer.collapsed = !this._selectedAction;
        }
    }

    private async _getLocation() {
        return new Promise<PentacodeAPIModels["Location"]>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude, longitude, accuracy } }) => {
                    resolve({ latitude, longitude, accuracy });
                },
                (e) => reject(e),
                {
                    enableHighAccuracy: true,
                }
            );
        });
    }

    private async _selectAction(action: SelectedAction) {
        if (action?.option.settings.requireLocation) {
            this._locationPromise = this._getLocation();
        }

        if (action?.option.settings.requirePhotoFor.includes(action.action)) {
            await this._takeImage();
        }

        this._selectedAction = action;
    }

    private async _takeImage() {
        this._image = await this._captureImageDialog.show({ countdown: 3, mirrorDisplay: true });
    }

    private async _submit(e: Event) {
        e.preventDefault();

        if (!this._selectedAction) {
            return;
        }

        const form = e.target as HTMLFormElement;
        const data = new FormData(form);

        const action = this._selectedAction.action;
        const comment = data.get("comment") as string;
        const image = this._image || undefined;
        const positionId = data.get("positionId") as string;
        const timeEntryId = data.get("timeEntryId") as string;
        const breakfast = data.has("breakfast");
        const lunch = data.has("lunch");
        const dinner = data.has("dinner");
        let location: PentacodeAPIModels["Location"] | undefined = undefined;

        this.loading = true;
        try {
            location = (await this._locationPromise) || undefined;
        } catch (e) {
            console.error("Failed to get location", e);
        }
        this.loading = false;

        this.done({
            employeeId: this._option.employee.id,
            action,
            comment,
            image,
            location,
            positionId: positionId ? Number(positionId) : undefined,
            timeEntryId: timeEntryId || undefined,
            meals:
                breakfast || lunch || dinner
                    ? {
                          breakfast,
                          lunch,
                          dinner,
                      }
                    : undefined,
        });
    }

    static styles = [
        ...Dialog.styles,
        Textarea.styles,
        Balance.styles,
        Checkbox.styles,
        animations,
        css`
            .inner {
                ${fullbleed()};
                --dialog-max-width: none;
            }

            .time-display {
                margin-bottom: -0.2em;
            }

            .stats-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-self: stretch;
                margin: 1em 0;
            }

            .stats-grid > :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .stats-grid > * {
                padding: 1em;
            }

            .stats-value {
                margin: 0.1em 0 -0.1em 0;
            }

            @media (max-width: 450px) {
                .stats-grid {
                    grid-template-columns: 1fr;
                }

                .stats-grid > :not(:last-child) {
                    border-bottom: solid 1px var(--shade-2);
                    border-right: none;
                }
            }
        `,
    ];

    private _renderMealOptions(option: ScheduledOption) {
        if (!this._selectedAction) {
            return;
        }
        const {
            settings: { meals },
            timeEntry,
        } = option;

        if (
            !timeEntry.startFinal ||
            (!meals?.breakfast?.available && !meals?.lunch?.available && !meals?.dinner?.available)
        ) {
            return;
        }

        const breakfastInterval =
            meals.breakfast?.available &&
            (parseTimes(timeEntry.date, meals.breakfast.start, meals.breakfast.end) as [Date, Date]);

        const lunchInterval =
            meals.lunch?.available && (parseTimes(timeEntry.date, meals.lunch.start, meals.lunch.end) as [Date, Date]);

        const dinnerInterval =
            meals.dinner?.available &&
            (parseTimes(timeEntry.date, meals.dinner.start, meals.dinner.end) as [Date, Date]);

        const workInterval = [new Date(timeEntry.startFinal), new Date()] as [Date, Date];

        if (meals.minDuration && workInterval[1].getTime() - workInterval[0].getTime() < meals.minDuration * hour) {
            return;
        }

        return html`
            <div class="small spacing vertical layout">
                ${breakfastInterval && overlap(workInterval, [breakfastInterval])
                    ? html`
                          <ptc-checkbox-button
                              buttonClass="slim ghost"
                              name="breakfast"
                              .label=${html` <i class="pancakes"></i> Frühstück `}
                          >
                          </ptc-checkbox-button>
                      `
                    : ""}
                ${lunchInterval && overlap(workInterval, [lunchInterval])
                    ? html`
                          <ptc-checkbox-button
                              buttonClass="slim ghost"
                              name="lunch"
                              .label=${html` <i class="burger-fries"></i> Mittagessen `}
                          >
                          </ptc-checkbox-button>
                      `
                    : ""}
                ${dinnerInterval && overlap(workInterval, [dinnerInterval])
                    ? html`
                          <ptc-checkbox-button
                              buttonClass="slim ghost"
                              name="dinner"
                              .label=${html` <i class="plate-utensils"></i> Abendessen `}
                          >
                          </ptc-checkbox-button>
                      `
                    : ""}
            </div>
        `;
    }

    private _renderActionForm() {
        if (!this._selectedAction) {
            return;
        }
        const { action, option } = this._selectedAction;
        const availablePositions = this._option.unscheduled;
        return html`
            <form
                class="padded spacing vertical layout"
                style="max-width: 25em; margin: 0 auto;"
                @submit=${this._submit}
            >
                <div class="blue colored-text semibold text-centering small-caps">
                    ${action === "startShift"
                        ? html` <i class="play-circle"></i> Schicht Beginnen `
                        : action === "startBreak"
                          ? html` <i class="coffee"></i> Pause Beginnen `
                          : action === "endBreak"
                            ? html` <i class="coffee"></i> Pause Beenden `
                            : action === "endShift"
                              ? html` <i class="door-open"></i> Schicht Beenden `
                              : action === "eatBreakfast"
                                ? html` <i class="pancakes"></i> Frühstück `
                                : action === "eatLunch"
                                  ? html` <i class="burger-fries"></i> Mittagessen `
                                  : action === "eatDinner"
                                    ? html` <i class="plate-untensils"></i> Abendessen `
                                    : html``}
                </div>

                ${"timeEntry" in option
                    ? html`
                          ${this._renderTimeEntry(option as ScheduledOption)}
                          ${action === "endShift" ? this._renderMealOptions(option) : html``}
                      `
                    : html`
                          <select name="positionId" ?readonly=${availablePositions.length === 1}>
                              ${availablePositions.map(
                                  (pos) => html`
                                      <option .value=${pos.position.id.toString()}>${pos.position.name}</option>
                                  `
                              )}
                          </select>
                      `}

                <div class="horizontal spacing start-aligning layout">
                    ${this._image
                        ? html`
                              <div class="relative vertical layout" @click=${this._takeImage}>
                                  <img .src=${this._image} style="width: 5.9em; height: 5.9em; border-radius: 0.5em;" />
                                  <div
                                      class="absolute bottom right semibold"
                                      style="color: var(--color-bg); border-radius: 0.5em; background: radial-gradient(circle at bottom right, rgba(0, 0, 0, 0.5), transparent 60%)"
                                  >
                                      <i class="refresh"></i>
                                  </div>
                              </div>
                          `
                        : ""}

                    <ptc-textarea
                        name="comment"
                        placeholder="Hier kannst Du optional einen Kommentar hizufügen."
                        class="stretch"
                    ></ptc-textarea>
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <button class="primary">Bestätigen</button>
                    <button class="transparent" type="button" @click=${() => this._selectAction(null)}>
                        Abbrechen
                    </button>
                </div>
            </form>
        `;
    }

    private _renderUnscheduled() {
        return html`
            <div class="spacing vertical layout">
                ${this._option.unscheduled.length ? html` ${this._renderAction("startShift")} ` : ""}

                <button class="transparent" @click=${() => this.done()}>Schließen</button>
            </div>
        `;
    }

    private _renderTimeEntry({ timeEntry }: ScheduledOption) {
        const breakDur =
            timeEntry.currentBreakStart &&
            (Date.now() - new Date(timeEntry.currentBreakStart).getTime()) / (3600 * 1000);
        const start = timeEntry.startPlanned || timeEntry.startFinal!;
        const end = timeEntry.endPlanned || timeEntry.endFinal;
        const duration =
            (timeEntry.endFinal &&
                timeEntry.startFinal &&
                (new Date(timeEntry.endFinal).getTime() - new Date(timeEntry.startFinal).getTime()) / 1000 / 60 / 60 -
                    (timeEntry.breakFinal || 0)) ||
            0;

        return html`
            <div class="padded box horizontal layout" style="--color-highlight: ${timeEntry.position!.color}">
                <div class="stretch">
                    <div class="ellipsis">${timeEntry.position!.name}</div>
                    <div class="huger time-display">${toTimeString(start)} - ${end ? toTimeString(end) : "offen"}</div>
                    ${timeEntry.comment
                        ? html`
                              <div class="smaller top-margined">
                                  <pre><i class="comment"></i> ${timeEntry.comment}</pre>
                              </div>
                          `
                        : ""}
                </div>
                <div class="larger vertical spacing layout">
                    ${timeEntry.status === "paused"
                        ? html`
                              <div class="blink orange smaller colored-text">
                                  <i class="coffee"></i> ${toDurationString(
                                      (breakDur || 0) + (timeEntry.breakLogged || 0)
                                  )}
                              </div>
                          `
                        : timeEntry.status === "ongoing"
                          ? html`
                                <div class="blink green smaller colored-text"><i class="play-circle"></i> aktiv</div>
                            `
                          : timeEntry.status === "scheduled"
                            ? html`
                                  <div class="smaller ${new Date() > new Date(start) ? "blink red colored-text" : ""}">
                                      <i class="stopwatch"></i> ${formatTimeDistance(start)}
                                  </div>
                              `
                            : html`
                                  <div class="green smaller colored-text">
                                      <i class="check"></i> ${toDurationString(duration)}
                                  </div>
                              `}
                </div>
            </div>
            <input type="hidden" name="timeEntryId" value=${timeEntry.id} />
        `;
    }

    private _renderActions(shift: PentacodeAPIModels["TimeLogOption"]["scheduled"][number]) {
        const actionOrder: TimeLogAction[] = ["startShift", "startBreak", "endBreak", "endShift"];
        return html`<div class="spacing vertical layout">
            ${shift.availableActions
                .sort((a, b) => actionOrder.indexOf(a) - actionOrder.indexOf(b))
                .map((action) => this._renderAction(action, shift))}

            <button class="transparent" @click=${() => this.done()}>Schließen</button>
        </div>`;
    }

    private _renderAction(action: TimeLogAction, shift?: PentacodeAPIModels["TimeLogOption"]["scheduled"][number]) {
        switch (action) {
            case "startShift":
                return shift
                    ? html`
                          <div class="shining vertical layout">
                              <button class="primary" @click=${() => this._selectAction({ action, option: shift })}>
                                  <i class="play-circle"></i> Schicht Beginnen
                              </button>
                          </div>
                      `
                    : html`
                          <button
                              class="primary"
                              @click=${() => this._selectAction({ action, option: this._option.unscheduled[0] })}
                          >
                              <i class="running"></i> Spontane Schicht
                          </button>
                      `;
            case "startBreak":
                return html`
                    <button class="orange ghost" @click=${() => this._selectAction({ action, option: shift! })}>
                        <i class="coffee"></i> Pause
                    </button>
                `;
            case "endBreak":
                return html`
                    <button class="orange ghost" @click=${() => this._selectAction({ action, option: shift! })}>
                        <i class="coffee"></i> Pause Beenden
                    </button>
                `;
            case "endShift":
                return html`
                    <button class="primary" @click=${() => this._selectAction({ action, option: shift! })}>
                        <i class="door-open"></i> Schicht Beenden
                    </button>
                `;
        }
    }

    private _renderUpcoming(opt: PentacodeAPIModels["TimeLogOption"]["scheduled"][number]) {
        if (!opt.timeEntry.startPlanned || !opt.settings) {
            return;
        }
        const startPlanned = new Date(opt.timeEntry.startPlanned);
        const earliestCheckin = new Date(startPlanned.getTime() - opt.settings.allowEarlyStart * hour);

        return html`
            <div class="spacing vertical layout">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="calendar"></i> Nächster Einsatz
                </div>

                ${this._renderTimeEntry(opt)}

                <button class="ghost" disabled>
                    <i class="play-circle"></i> Stempeln möglich ${formatTimeDistance(earliestCheckin)}
                </button>

                <div class="padded subtle text-centering semibold">oder</div>

                ${this._renderUnscheduled()}
            </div>
        `;
    }

    private _renderStartable(opts: PentacodeAPIModels["TimeLogOption"]["scheduled"][number]) {
        return html`
            <div class="spacing vertical layout">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="play-circle"></i> Bereit Zum Stempeln
                </div>

                ${this._renderTimeEntry(opts)} ${this._renderActions(opts)}
            </div>
        `;
    }

    private _renderActive(shift: PentacodeAPIModels["TimeLogOption"]["scheduled"][number]) {
        return html`
            <div class="spacing vertical layout" style="min-width: 20em">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="clock"></i> Aktive Schicht
                </div>

                ${this._renderTimeEntry(shift)} ${this._renderActions(shift)}
            </div>
        `;
    }

    private _renderCompleted(shift: PentacodeAPIModels["TimeLogOption"]["scheduled"][number]) {
        return html`
            <div class="spacing vertical layout bottom-margined">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="check"></i> Schicht Abgeschlossen
                </div>

                ${this._renderTimeEntry(shift)}
            </div>
        `;
    }

    private _renderNoShift() {
        return html`
            <div class="spacing vertical layout">
                <div class="horizontally-padded text-centering subtle semibold small-caps">
                    <i class="calendar-times"></i> Keine geplante Schicht gefunden
                </div>

                ${this._renderUnscheduled()}
            </div>
        `;
    }

    private _renderMonthTimeBalance() {
        if (!this._monthTimeBalances) {
            return;
        }

        return until(
            this._monthTimeBalances.then((balances) => {
                if (!balances.length) {
                    return;
                }
                const date = parseDateString(balances[0].from)!;
                return html`
                    <div class="">
                        <div class="horizontal spacing center-aligning layout">
                            <div class="blue colored-text semibold stretch">Stunden</div>
                            <div class="subtle smaller semibold">
                                ${monthNames[date.getMonth()].slice(0, 3)} ${date.getFullYear()}
                            </div>
                        </div>
                        <div class="horizontal end-aligning layout">
                            <div class="huger stretch stats-value">
                                ${toDurationString(balances.reduce((total, b) => add(total, b.actual), 0 as Hours))}
                            </div>
                            <div class="semibold faded">
                                / ${toDurationString(balances.reduce((total, b) => add(total, b.nominal), 0 as Hours))}
                            </div>
                        </div>
                    </div>
                `;
            }),
            html`
                <div class="strong shining">
                    <div class="horizontal spacing center-aligning layout">
                        <div class="blue colored-text semibold stretch">Stunden</div>
                        <div class="subtle smaller semibold">...</div>
                    </div>
                    <div class="horizontal end-aligning layout">
                        <div class="huger stretch stats-value">...</div>
                        <div class="semibold faded">/ ...</div>
                    </div>
                </div>
            `
        );
    }

    private _renderDayTimeBalance() {
        if (!this._dayTimeBalances) {
            return;
        }

        return until(
            this._dayTimeBalances.then(
                (balances) =>
                    balances.length &&
                    html`
                        <div class="">
                            <div class="horizontal spacing center-aligning layout">
                                <div class="blue colored-text semibold stretch">Überst.</div>
                                <div class="subtle smaller semibold">Stand ${formatDateShort(balances[0].from)}</div>
                            </div>
                            <div class="horizontal end-aligning layout">
                                <ptc-balance
                                    class="huger stats-value"
                                    .value=${balances[0].reset ?? balances[0].carry}
                                    .format=${(val: Hours) => toDurationString(val, true)}
                                ></ptc-balance>
                            </div>
                        </div>
                    `
            ),
            html`
                <div class="strong shining">
                    <div class="horizontal spacing center-aligning layout">
                        <div class="blue colored-text semibold stretch">Überst.</div>
                        <div class="subtle smaller semibold">...</div>
                    </div>
                    <div class="horizontal end-aligning layout">
                        <div class="huger stretch stats-value">...</div>
                    </div>
                </div>
            `
        );
    }

    private _renderYearVacationBalance() {
        if (!this._vacationBalances) {
            return;
        }

        return until(
            this._vacationBalances.then((balances) => {
                if (!balances.length) {
                    return;
                }
                const entitlement = getVacationEntitlement(balances[0]);
                return html`
                    <div class="">
                        <div class="horizontal spacing center-aligning layout">
                            <div class="blue colored-text semibold stretch">Resturlaub</div>
                            <div class="subtle smaller semibold">
                                ${parseDateString(balances[0].from)!.getFullYear()}
                            </div>
                        </div>
                        <div class="horizontal end-aligning layout">
                            <div class="huger stretch stats-value">${formatNumber(entitlement.remaining, 2)}</div>
                            <div class="semibold faded">/ ${formatNumber(entitlement.available, 2)}</div>
                        </div>
                    </div>
                `;
            }),
            html`
                <div class="strong shining">
                    <div class="horizontal spacing center-aligning layout">
                        <div class="blue colored-text semibold stretch">Resturlaub</div>
                        <div class="subtle smaller semibold">...</div>
                    </div>
                    <div class="horizontal end-aligning layout">
                        <div class="huger stretch stats-value">...</div>
                        <div class="semibold faded">/ ...</div>
                    </div>
                </div>
            `
        );
    }

    private _renderEmployeeInfo() {
        const employee = this._option.employee;
        return html`
            <div class="vertical centering layout fit-vertically">
                <ptc-avatar class="huge" .employee=${employee}></ptc-avatar>
                <div class="large semibold vertically-margined">${employee.firstName} ${employee.lastName}</div>
                <div class="small stats-grid vertically-double-margined" ?hidden=${!this._option.displayStatistics}>
                    ${this._renderMonthTimeBalance()} ${this._renderDayTimeBalance()}
                    ${this._renderYearVacationBalance()}
                </div>
            </div>
        `;
    }

    private _renderNotes() {
        if (!this._notes) {
            return;
        }

        return until(
            this._notes.then(
                (notes) =>
                    html`
                        <div>
                            <div class="subtle horizontally-padded semibold text-centering small-caps">
                                <i class="sticky-note"></i> Notizen
                            </div>
                            ${notes.map(
                                (note) =>
                                    html`<ptc-note
                                        .note=${note}
                                        .employee=${this._option.employee}
                                        class="top-margined"
                                    ></ptc-note>`
                            )}
                        </div>
                    ` as any
            ),
            html``
        );
    }

    renderContent() {
        if (!this._option) {
            return html``;
        }
        const { ready, paused, ongoing, upcoming, completed } = getCurrentShiftsByStatus(this._option);

        return html`
            <div class="fullbleed vertical layout">
                <button class="double-margined absolute top right subtle icon" @click=${() => this.cancel()}>
                    <i class="times" style="line-height: 1.5em"></i>
                </button>
                <div class="stretch"></div>
                <ptc-drawer id="detailsDrawer" class="fit-vertically">
                    <ptc-scroller class="fit-vertically" style="max-width: 32em; max-height: 100vh; margin: 0 auto;">
                        <div class="double-padded">
                            ${this._renderEmployeeInfo()} ${this._renderNotes()}
                            <div class="double-padded" style="max-width: 25em; margin: 0 auto;">
                                ${completed && !ongoing && !paused ? this._renderCompleted(completed) : ""}
                                ${paused
                                    ? this._renderActive(paused)
                                    : ongoing
                                      ? this._renderActive(ongoing)
                                      : ready
                                        ? this._renderStartable(ready)
                                        : upcoming
                                          ? this._renderUpcoming(upcoming)
                                          : this._renderNoShift()}
                            </div>
                        </div>
                    </ptc-scroller>
                </ptc-drawer>
                <ptc-drawer id="formDrawer" class="fit-vertically">${this._renderActionForm()}</ptc-drawer>
                <div class="stretch"></div>
            </div>
        `;
    }
}
