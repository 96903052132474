import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import {
    Department,
    EmployeeStatus,
    employeeStatusColor,
    employeeStatusIcon,
    employeeStatusLabel,
    employmentTypeLabel,
    getEmploymentTypes,
    EntityFilter,
} from "@pentacode/core/src/model";
import { app } from "../init";
import { StateMixin } from "../mixins/state";
import { colors, shared } from "../styles";
import "./avatar";
import "./popover";
import "./scroller";
import { matchesFilters } from "@pentacode/core/src/filters";
import "./drawer";

@customElement("ptc-entity-filters")
export class EntityFiltersEl extends StateMixin(LitElement) {
    static getFilterLabel(filter: EntityFilter) {
        switch (filter.type) {
            case "venue":
                return app.getVenue(filter.value as number)?.name || "Unbekannter Standort";
            case "department": {
                const { department } = app.getDepartment(filter.value as number);
                return department?.name || "Unbekannte Abteilung";
            }
            case "position": {
                const position = app.getPosition(filter.value as number)?.position;
                return position?.name || "Unbekannte Position";
            }
            case "employmentType":
                return employmentTypeLabel(filter.value);
            case "employeeStatus":
                return employeeStatusLabel(filter.value);
            case "employeeId": {
                const employee = app.getEmployee(filter.value);
                return employee && `${employee.lastName}, ${employee.firstName}`;
            }
            case "employeeTag":
                return app.company?.employeeTags.find((t) => t.id === filter.value)?.name;
            case "costCenter": {
                const costCenter = app.company?.costCenters.find((cc) => cc.number === filter.value);
                return (
                    costCenter &&
                    html`${costCenter.name} <span class="smaller subtle semibold">(#${costCenter.number})</span></span>`
                );
            }
        }
    }

    static getFilterIcon(filter: EntityFilter) {
        switch (filter.type) {
            case "venue":
                return html`<i class="people-roof"></i>`;
            case "department":
                return html`<i class="people-line"></i>`;
            case "position":
                return html`<i class="arrows-down-to-people"></i>`;
            case "employmentType":
                return html`<i class="file-contract"></i>`;
            case "employeeStatus":
                return html`<i class=${employeeStatusIcon(filter.value)}></i>`;
            case "employeeTag":
                return html`<i class="tag"></i>`;
            case "costCenter":
                return html`<i class="chart-pie-simple-circle-dollar"></i>`;
            case "employeeId":
                return html`<ptc-avatar
                    class="tinier"
                    style="margin-top: -1px; margin-bottom: -1px;"
                    .employee=${app.getEmployee(filter.value)}
                ></ptc-avatar>`;
        }
    }

    static getFilterColor(filter: EntityFilter) {
        switch (filter.type) {
            case "venue":
                return "";
            case "department": {
                const { department } = app.getDepartment(filter.value as number);
                return department && (colors[department.color] || department.color);
            }
            case "position": {
                const position = app.getPosition(filter.value as number)?.position;
                return position && app.getPositionColor(position);
            }
            case "employeeStatus":
                return employeeStatusColor(filter.value);
            case "employeeId":
                return "inherit";
            case "employeeTag":
                return app.company?.employeeTags.find((t) => t.id === filter.value)?.color;
            case "employmentType":
            case "costCenter":
            case "staffNumber":
                return "";
        }
    }

    @property({ attribute: false })
    filters: EntityFilter[] = [];

    @property({ attribute: false })
    filterTypes: EntityFilter["type"][] = [
        "employeeStatus",
        "venue",
        "department",
        "position",
        "employmentType",
        "employeeId",
        "employeeTag",
        "costCenter",
    ];

    @property({ type: Boolean })
    readonly: boolean = false;

    @property({ type: Boolean })
    hideFilterIcon: boolean = false;

    @property({ type: Boolean })
    hideEmployeeCount: boolean = false;

    @property()
    icon = "filter";

    @property()
    emptyIcon = "people-group";

    @property()
    emptyLabel = "Alle Mitarbeiter";

    @property()
    noOptionsLabel = "Keine passenden Ergebnisse";

    get filteredEmployees() {
        return app.accessibleEmployees.filter((employee) =>
            matchesFilters(this.filters, { company: app.company!, employee })
        );
    }

    @state()
    private _filterSearchString: string = "";

    @state()
    private _maxDisplayEmployees = 10;

    @state()
    private _collapsedSections: Set<string> = new Set();

    @query("#filterSearch")
    private _filterSearchInput: HTMLInputElement;

    private get _availableVenues() {
        if (!this.filterTypes.includes("venue")) {
            return [];
        }
        return app.accessibleVenues.filter(
            (venue) =>
                !this.filters.some((filter) => filter.type === "venue" && filter.value === venue.id) &&
                venue.name.toLowerCase().includes(this._filterSearchString.toLowerCase()) &&
                app.hasAccess({ venue })
        );
    }

    private get _availableDepartments() {
        if (!this.filterTypes.includes("department")) {
            return [];
        }
        return app.accessibleDepartments.filter(
            (department) =>
                !this.filters.some(
                    (filter) =>
                        (filter.type === "department" && filter.value === department.id) ||
                        (filter.type === "venue" && filter.value === department.venueId)
                ) && department.name.toLowerCase().includes(this._filterSearchString.toLowerCase())
        );
    }

    private get _availablePositions() {
        if (!this.filterTypes.includes("position")) {
            return [];
        }
        return app.accessiblePositions.filter(
            (pos) =>
                !this.filters.some(
                    (filter) =>
                        (filter.type === "position" && filter.value === pos.id) ||
                        (filter.type === "department" && filter.value === pos.departmentId) ||
                        (filter.type === "venue" && filter.value === app.getDepartment(pos.departmentId).venue?.id)
                ) && pos.name.toLowerCase().includes(this._filterSearchString.toLowerCase())
        );
    }

    private get _availableEmployees() {
        if (!this.filterTypes.includes("employeeId")) {
            return [];
        }
        return app.accessibleEmployees.filter(
            (e) =>
                !this.filters.some((filter) => filter.type === "employeeId" && filter.value === e.id) &&
                (this._filterSearchString || e.status === EmployeeStatus.Active) &&
                e.name.toLowerCase().includes(this._filterSearchString.toLowerCase())
        );
    }

    private get _availableEmploymentTypes() {
        if (!this.filterTypes.includes("employmentType")) {
            return [];
        }
        return getEmploymentTypes().filter(
            ({ type, label }) =>
                !this.filters.some((filter) => filter.type === "employmentType" && filter.value === type) &&
                label.toLowerCase().includes(this._filterSearchString.toLowerCase())
        );
    }

    private get _availableEmployeeStatuses() {
        if (!this.filterTypes.includes("employeeStatus")) {
            return [];
        }
        const statuses = Object.values(EmployeeStatus);

        return statuses.filter(
            (s) =>
                !this.filters.some((filter) => filter.type === "employeeStatus" && filter.value === s) &&
                employeeStatusLabel(s).toLowerCase().includes(this._filterSearchString.toLowerCase())
        );
    }

    private get _availableEmployeeTags() {
        if (!this.filterTypes.includes("employeeTag")) {
            return [];
        }
        return (
            app.company?.employeeTags?.filter(
                (tag) =>
                    !this.filters.some((filter) => filter.type === "employeeTag" && filter.value === tag.id) &&
                    tag.name.toLowerCase().includes(this._filterSearchString.toLowerCase())
            ) || []
        );
    }

    private get _availableCostCenters() {
        if (!this.filterTypes.includes("costCenter")) {
            return [];
        }
        return (
            app.company?.costCenters?.filter(
                (cc) =>
                    !this.filters.some((filter) => filter.type === "costCenter" && filter.value === cc.number) &&
                    cc.name.toLowerCase().includes(this._filterSearchString.toLowerCase())
            ) || []
        );
    }

    private _toggleSection(section: string) {
        if (this._collapsedSections.has(section)) {
            this._collapsedSections.delete(section);
        } else {
            this._collapsedSections.add(section);
        }
        this.requestUpdate();
    }

    private async _addFilter(filter: EntityFilter) {
        let existingFilters = this.filters;

        if (filter.type === "venue") {
            // If a venue is selected, remove all department and position filters within that venue,
            // as they are redundant.
            existingFilters = existingFilters.filter(
                (f) =>
                    !(f.type === "department" && app.getDepartment(f.value)?.venue?.id === filter.value) &&
                    !(f.type === "position" && app.getPosition(f.value)?.department?.venueId === filter.value)
            );
        }

        if (filter.type === "department") {
            // If a department is selected, remove all position filters within that department,
            // as they are redundant.
            existingFilters = existingFilters.filter(
                (f) => !(f.type === "position" && app.getPosition(f.value)?.department?.id === filter.value)
            );
        }

        this.filters = [...existingFilters, filter];
        this.dispatchEvent(new CustomEvent("change", { detail: { filters: app.state.employeeFilters } }));
        this._filterSearchInput.value = this._filterSearchString = "";
        this._filterSearchInput.focus();
    }

    private _removeFilter(filter: EntityFilter) {
        this.filters = this.filters.filter((f) => f.type !== filter.type || f.value !== filter.value);
        this.dispatchEvent(new CustomEvent("change", { detail: { filters: app.state.employeeFilters } }));
        this._filterSearchInput.focus();
    }

    private _renderFilterOptions() {
        const statuses = this._availableEmployeeStatuses;
        const employmentTypes = this._availableEmploymentTypes;
        const venues = this._availableVenues;
        const departments = this._availableDepartments;
        const positions = this._availablePositions;
        const employees = this._availableEmployees;
        const tags = this._availableEmployeeTags;
        const costCenters = this._availableCostCenters;

        const renderDepartment = (dep: Department, indent = false) => {
            const isDepartmentSelectible = departments.some((d) => d.id === dep.id);
            const positionsInDepartment = positions.filter((pos) => pos.departmentId === dep.id);
            if (!isDepartmentSelectible && !positionsInDepartment.length) {
                return;
            }
            return html`
                <div
                    class="padded rounded click center-aligning spacing horizontal layout colored-text"
                    style="--color-highlight: ${colors[dep.color] || dep.color}; margin-left: ${indent ? "1em" : "0"};"
                    @click=${() => this._addFilter({ type: "department", value: dep.id })}
                    ?disabled=${!isDepartmentSelectible}
                >
                    <i class="people-line"></i>
                    <div class="stretch collapse ellipsis">${dep.name}</div>
                </div>
                ${positionsInDepartment.map(
                    (pos) => html`
                        <div
                            class="padded rounded click center-aligning spacing horizontal layout colored-text"
                            style="--color-highlight: ${app.getPositionColor(pos)}; margin-left: ${indent
                                ? "2em"
                                : "1em"};"
                            @click=${() =>
                                this._addFilter({
                                    type: "position",
                                    value: pos.id,
                                })}
                        >
                            <i class="arrows-down-to-people"></i>
                            <div class="stretch collapse ellipsis">${pos.name}</div>
                        </div>
                    `
                )}
            `;
        };

        const noMatches = !statuses.length && !employmentTypes.length && !venues.length && !departments.length;

        return html`
            <ptc-scroller class="stretch">
                ${noMatches ? html`<div class="padded faded text-centering">${this.noOptionsLabel}</div>` : ""}
                ${statuses.length
                    ? html`
                          <button
                              class="smaller blue skinny transparent text-left-aligning fill-horizontally"
                              @click=${() => this._toggleSection("employeeStatus")}
                          >
                              <div
                                  class="${this._collapsedSections.has("employeeStatus")
                                      ? ""
                                      : "subtle"} center-aligning horizontal layout"
                              >
                                  <div class="stretch">Mitarbeiterstatus</div>
                                  <div>${statuses.length}</div>
                                  <i
                                      class="${this._collapsedSections.has("employeeStatus")
                                          ? "circle-caret-right"
                                          : "circle-caret-down"}"
                                  ></i>
                              </div>
                          </button>
                          <ptc-drawer .collapsed=${this._collapsedSections.has("employeeStatus")}>
                              ${statuses.map(
                                  (s: EmployeeStatus) => html`
                                      <div
                                          class="padded rounded click center-aligning spacing horizontal layout colored-text"
                                          style="--color-highlight: ${employeeStatusColor(s)}"
                                          @click=${() => this._addFilter({ type: "employeeStatus", value: s })}
                                      >
                                          <i class="${employeeStatusIcon(s)}"></i>
                                          <div class="stretch collapse ellipsis">${employeeStatusLabel(s)}</div>
                                      </div>
                                  `
                              )}
                          </ptc-drawer>
                      `
                    : ""}
                ${employmentTypes.length
                    ? html`
                          <button
                              class="smaller blue skinny transparent text-left-aligning fill-horizontally"
                              @click=${() => this._toggleSection("employmentType")}
                          >
                              <div
                                  class="${this._collapsedSections.has("employmentType")
                                      ? ""
                                      : "subtle"} center-aligning horizontal layout"
                              >
                                  <div class="stretch">Beschäftigungsverhältnis</div>
                                  <div>${employmentTypes.length}</div>
                                  <i
                                      class="${this._collapsedSections.has("employmentType")
                                          ? "circle-caret-right"
                                          : "circle-caret-down"}"
                                  ></i>
                              </div>
                          </button>
                          <ptc-drawer .collapsed=${this._collapsedSections.has("employmentType")}>
                              ${employmentTypes.map(
                                  ({ type, label }) => html`
                                      <div
                                          class="padded rounded click center-aligning spacing horizontal layout"
                                          @click=${() => this._addFilter({ type: "employmentType", value: type })}
                                      >
                                          <i class="file-contract"></i>
                                          <div class="stretch collapse ellipsis">${label}</div>
                                      </div>
                                  `
                              )}
                          </ptc-drawer>
                      `
                    : ""}
                ${tags.length
                    ? html`
                          <button
                              class="smaller blue skinny transparent text-left-aligning fill-horizontally"
                              @click=${() => this._toggleSection("employeeTags")}
                          >
                              <div
                                  class="${this._collapsedSections.has("employeeTags")
                                      ? ""
                                      : "subtle"} center-aligning horizontal layout"
                              >
                                  <div class="stretch">Fähigkeiten & Merkmale</div>
                                  <div>${tags.length}</div>
                                  <i
                                      class="${this._collapsedSections.has("employeeTags")
                                          ? "circle-caret-right"
                                          : "circle-caret-down"}"
                                  ></i>
                              </div>
                          </button>
                          <ptc-drawer .collapsed=${this._collapsedSections.has("employeeTags")}>
                              ${tags.map(
                                  ({ id, name, color }) => html`
                                      <div
                                          class="padded rounded click center-aligning spacing horizontal layout colored-text"
                                          style="--color-highlight: ${color}"
                                          @click=${() => this._addFilter({ type: "employeeTag", value: id })}
                                      >
                                          <i class="tag"></i>
                                          <div class="stretch collapse ellipsis">${name}</div>
                                      </div>
                                  `
                              )}
                          </ptc-drawer>
                      `
                    : ""}
                ${costCenters.length
                    ? html`
                          <button
                              class="smaller blue skinny transparent text-left-aligning fill-horizontally"
                              @click=${() => this._toggleSection("costCenters")}
                          >
                              <div
                                  class="${this._collapsedSections.has("costCenters")
                                      ? ""
                                      : "subtle"} center-aligning horizontal layout"
                              >
                                  <div class="stretch">Kostenstellen</div>
                                  <div>${costCenters.length}</div>
                                  <i
                                      class="${this._collapsedSections.has("costCenters")
                                          ? "circle-caret-right"
                                          : "circle-caret-down"}"
                                  ></i>
                              </div>
                          </button>
                          <ptc-drawer .collapsed=${this._collapsedSections.has("costCenters")}>
                              ${costCenters.map(
                                  ({ name, number }) => html`
                                      <div
                                          class="padded rounded click center-aligning spacing horizontal layout"
                                          @click=${() => this._addFilter({ type: "costCenter", value: number })}
                                      >
                                          <i class="chart-pie-simple-circle-dollar"></i>
                                          <div class="stretch collapse ellipsis">
                                              ${name} <span class="smaller subtle semibold">(#${number})</span>
                                          </div>
                                      </div>
                                  `
                              )}
                          </ptc-drawer>
                      `
                    : ""}
                ${venues.length || departments.length || positions.length
                    ? html`
                          <button
                              class="smaller blue skinny transparent text-left-aligning fill-horizontally"
                              @click=${() => this._toggleSection("workArea")}
                          >
                              <div
                                  class="${this._collapsedSections.has("workArea")
                                      ? ""
                                      : "subtle"} center-aligning horizontal layout"
                              >
                                  <div class="stretch">Arbeitsbereiche</div>
                                  <div>${venues.length + departments.length + positions.length}</div>
                                  <i
                                      class="${this._collapsedSections.has("workArea")
                                          ? "circle-caret-right"
                                          : "circle-caret-down"}"
                                  ></i>
                              </div>
                          </button>
                          <ptc-drawer .collapsed=${this._collapsedSections.has("workArea")}>
                              ${app.accessibleVenues.length === 1
                                  ? html` ${app.accessibleDepartments.map((dep) => renderDepartment(dep))} `
                                  : app.accessibleVenues.map((venue) => {
                                        const departmentsInVenue = app.accessibleDepartments.filter(
                                            (dep) =>
                                                dep.venueId === venue.id &&
                                                (departments.some((d) => dep.id === d.id) ||
                                                    positions.some((pos) => pos.departmentId === dep.id))
                                        );
                                        const isVenueSelectible = venues.some((v) => v.id === venue.id);

                                        if (!isVenueSelectible && !departmentsInVenue.length) {
                                            return;
                                        }

                                        return html`
                                            <div
                                                class="padded rounded click center-aligning spacing horizontal layout"
                                                @click=${() => this._addFilter({ type: "venue", value: venue.id })}
                                                ?disabled=${!isVenueSelectible}
                                            >
                                                <i class="people-roof"></i>
                                                <div class="stretch collapse ellipsis">${venue.name}</div>
                                            </div>

                                            ${departmentsInVenue.map((dep) => renderDepartment(dep, true))}
                                        `;
                                    })}
                          </ptc-drawer>
                      `
                    : ""}
                ${employees.length
                    ? html`
                          <button
                              class="smaller blue skinny transparent text-left-aligning fill-horizontally"
                              @click=${() => this._toggleSection("employees")}
                          >
                              <div
                                  class="${this._collapsedSections.has("employees")
                                      ? ""
                                      : "subtle"} center-aligning horizontal layout"
                              >
                                  <div class="stretch">Mitarbeiter</div>
                                  <div>${employees.length}</div>
                                  <i
                                      class="${this._collapsedSections.has("employees")
                                          ? "circle-caret-right"
                                          : "circle-caret-down"}"
                                  ></i>
                              </div>
                          </button>
                          <ptc-drawer .collapsed=${this._collapsedSections.has("employees")}>
                              <div class="relative">
                                  ${employees.slice(0, this._maxDisplayEmployees).map(
                                      (e) => html`
                                          <div
                                              class="padded rounded click center-aligning spacing horizontal layout"
                                              @click=${() => this._addFilter({ type: "employeeId", value: e.id })}
                                          >
                                              <ptc-avatar class="tinier" .employee=${e}></ptc-avatar>
                                              <div class="stretch collapse ellipsis">${e.lastName}, ${e.firstName}</div>
                                              ${e.staffNumber
                                                  ? html`<div class="smaller subtle">#${e.staffNumber}</div>`
                                                  : ""}
                                          </div>
                                      `
                                  )}
                                  ${employees.length > this._maxDisplayEmployees
                                      ? html`
                                            <div
                                                class="padded rounded click text-centering subtle"
                                                @click=${() => (this._maxDisplayEmployees += 10)}
                                            >
                                                Mehr Anzeigen...
                                            </div>
                                        `
                                      : ""}
                              </div>
                          </ptc-drawer>
                      `
                    : ""}
            </ptc-scroller>
        `;
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                position: relative;
                z-index: 9;
                overflow: visible;
                padding: 0.3em;
            }

            .filter-item:not(:hover) .remove-icon {
                display: none;
            }

            .search-input {
                padding: 0.2em;
                background: transparent;
                border: none;
                max-width: 15em;
            }

            .filters-popover {
                padding: 0.5em;
                width: 25em;
                max-height: 50vh;
                z-index: 10;
            }
        `,
    ];

    render() {
        return html`
            <div class="start-aligning spacing horizontal layout">
                ${this.hideFilterIcon
                    ? ""
                    : html` <i class="${this.icon}" style="margin: 0.1em 0.3em 0.1em 0.1em;"></i> `}
                <div class="start-aligning spacing wrapping horizontal layout stretch">
                    ${this.filters.length === 0
                        ? html`
                              <div
                                  class="filter-item smaller half-padded box half-spacing horizontal center-aligning layout non-interactive"
                              >
                                  ${this.emptyIcon ? html`<i class="${this.emptyIcon}"></i> ` : ""}
                                  <div>${this.emptyLabel}</div>
                              </div>
                          `
                        : ""}
                    ${this.filters
                        .sort((a, b) => this.filterTypes.indexOf(a.type) - this.filterTypes.indexOf(b.type))
                        .map((filter) =>
                            this.readonly
                                ? html`
                                      <div
                                          class="filter-item smaller half-padded box half-spacing horizontal center-aligning layout"
                                          style="--color-highlight: ${EntityFiltersEl.getFilterColor(filter)}"
                                      >
                                          ${EntityFiltersEl.getFilterIcon(filter)}
                                          <div>${EntityFiltersEl.getFilterLabel(filter)}</div>
                                      </div>
                                  `
                                : html`
                                      <div
                                          class="filter-item smaller half-padded box half-spacing horizontal center-aligning layout click"
                                          @click=${() => this._removeFilter(filter)}
                                          style="--color-highlight: ${EntityFiltersEl.getFilterColor(filter)}"
                                      >
                                          ${EntityFiltersEl.getFilterIcon(filter)}
                                          <div>${EntityFiltersEl.getFilterLabel(filter)}</div>
                                          <i class="smaller times remove-icon"></i>
                                      </div>
                                  `
                        )}
                    ${this.readonly
                        ? ""
                        : html`
                              <input
                                  id="filterSearch"
                                  class="search-input stretch"
                                  @input=${() => (this._filterSearchString = this._filterSearchInput.value)}
                                  placeholder="Filter Hinzufügen..."
                              />
                              <ptc-popover
                                  class="filters-popover vertical layout"
                                  trigger="focus"
                                  style="--popover-hover-buffer: 0;"
                                  @popover-hide=${() => (this._maxDisplayEmployees = 10)}
                              >
                                  ${this._renderFilterOptions()}
                              </ptc-popover>
                          `}
                </div>

                ${this.hideEmployeeCount
                    ? ""
                    : html`
                          <div class="smaller subtle semibold half-padded">
                              ${this.filteredEmployees.length} <i class="people-group"></i>
                          </div>
                      `}
            </div>
        `;
    }
}
